<template>
  <div>
    <div class="grid grid-cols-1 md:grid-cols-2 mb-4">
      <div class="col-span-1 flex items-center flex-wrap">
        <h2 class="text-2xl font-bold mr-3">Virtual Cards</h2>
      </div>

      <div class="col-span-1 flex gap-1">
        <select
          name=""
          id=""
          class="border border-solid border-blue-200 rounded-sm placeholder-blue-300 outline-none py-3 text-13 text-gray-600 w-[50px] px-1"
          v-model="virtualCards.selectFilter"
        >
          <option value="">No Filter</option>
          <option value="currency">by Card Type</option>
          <option value="reference">By Reference</option>
          <option value="userId">By User ID</option>
        </select>

        <search-form
          v-model="virtualCards.queryValue"
          @submit="(event) => $refs.table.loadAjaxData()"
          v-if="virtualCards.selectFilter"
        />
      </div>
    </div>
    <div class="border border-solid border-blue-200">
      <datatable
        :index="true"
        :url="`${this.$baseurl}/cards/admin/cards`"
        :ajax="true"
        :onClick="goToUser"
        :fillable="true"
        :ajaxPagination="true"
        :data="virtualCards.data"
        :columns="virtualCards.columns"
        :selectFilter="virtualCards.selectFilter"
        :queryValue="virtualCards.queryValue"
        ref="table"
        :sorted="true"
        :dont_sort="virtualCards.dont_sort"
      />
    </div>
  </div>
</template>
<script>
import moment from 'moment';
export default {
  data() {
    return {
      virtualCards: this.$options.resource([], {
        selectFilter: '',
        queryValue: '',
        dont_sort: ['user'],
        columns: [
          {
            name: 'user',
            th: 'User',
            render: (transaction) => {
              if (!transaction.user) {
                return 'N/A';
              }
              return `${transaction?.user?.firstName} ${
                transaction?.user?.lastName || ''
              }`;
            },
          },
          {
            name: 'cardId',
            th: 'Virtual Card Id',
            render: (card) => {
              return `${card?.cardId || 'N/A'}`;
            },
          },

          {
            name: 'cardType',
            th: 'cardType',
          },
          {
            name: 'cardBrand',
            th: 'cardBrand',
          },
          {
            name: 'lastFourDigits',
            th: 'lastFourDigits',
          },
          {
            name: 'balance',
            th: 'Balance',
            render: (card) => `${this.$options.filters.currency(card.balance)}`,
          },
          {
            name: 'cardCurrency',
            th: 'Currency',
          },

          {
            name: 'currentLimit',
            th: 'currentLimit',
          },
          {
            name: 'isActive',
            th: 'is active',
            render: (status) =>
              status.isActive
                ? `<span class="text-green-400">${status.isActive}</span>`
                : `<span class="text-red-400">${status.isActive}</span>`,
          },
          {
            name: 'created_at',
            th: 'Date Created',
            render: (transaction) => {
              return moment(transaction?.createdAt).format('MMM Do YYYY');
            },
          },
        ],
      }),
    };
  },

  methods: {
    goToUser({ user }) {
      return this.$router.push({
        name: 'staff-view',
        params: {
          staffId: user?.userId,
        },
      });
    },
  },
  watch: {
    // Watch for changes in selectFilter
    'virtualCards.selectFilter'(newValue) {
      if (!newValue) {
        this.virtualCards.queryValue = ''; // Reset queryValue if no filter is selected
      }
    },
  },
};
</script>
